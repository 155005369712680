<!--
 * @Author: lbh
 * @Date: 2024-08-29 12:10:27
 * @LastEditors: lbh
 * @LastEditTime: 2024-09-05 15:57:19
 * @Description: file content
-->
<template>
  <div class="px-cell-swiper-edit">
    <div class="cell-b">
      <selfCell title="展示頂部圖片">
        <el-switch
          v-model="configs.showTopImg"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="setValue('showTopImg')"
        >
        </el-switch>
      </selfCell>
    </div>
    <div class="cell-b">
      <selfCell title="背景色">
        <el-color-picker
          v-model="configs.bgColor"
          @change="setValue('bgColor')"
        ></el-color-picker>
      </selfCell>
      <selfCell title="背景圖">
        <selfUpload
          v-model="configs.bg"
          type="default"
          @change="setValue('bg')"
        />
      </selfCell>
      <selfCell title="展示列數">
        <el-input-number
          v-model="configs.column"
          @change="setValue('column')"
          :min="1"
          :max="10"
          label="描述文字"
        ></el-input-number>
      </selfCell>
      <selfCell title="標題" :column="true">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code == 'HK' ? '' : lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code == 'HK' ? '' : lItem.code}title`)"
        />
      </selfCell>

      <selfCell title="描述" :column="true">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code == 'HK' ? '' : lItem.code}desc`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code == 'HK' ? '' : lItem.code}desc`)"
          type="textarea"
          rows="5"
        />
      </selfCell>
    </div>

    <div class="cell-b">
      <selfCell title="描述" :column="true">
        <self-upload-d v-model="configs.items" @change="setValue('items')" />
      </selfCell>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit';
import editModuleMixin from '../../mixins/editModuleMixin';
import selfUploadD from '@/components/upload-d';
export default {
  name: 'cell-swiper-edit',
  mixins: [langEditMixin, editModuleMixin],
  components: {
    selfUploadD,
  },
};
</script>

<style lang="less" scoped></style>
