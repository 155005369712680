<!--
 * @Author: lbh
 * @Date: 2022-02-24 15:34:34
 * @LastEditors: lbh
 * @LastEditTime: 2024-08-29 15:05:28
 * @Description: 上傳圖片
-->
<template>
  <div class="com__upload-box">
    <el-upload
      class="avatar-uploader"
      :class="type"
      action="#"
      :http-request="uploadFile"
      :show-file-list="false"
      :multiple="true"
    >
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div v-for="(item, index) in urls" :key="index" class="px-img">
      <el-button
        type="danger"
        icon="el-icon-delete"
        class="remove-img"
        size="mini"
        circle
        @click="removeFile(index)"
      ></el-button>
      <img
        v-if="item"
        :style="`border-radius:${radius}px`"
        :src="item"
        class="avatar"
      />
    </div>
  </div>
</template>

<script>
import { getSignature, ossUploadImg } from '@/js/api/api';
export default {
  name: 'self-upload',
  props: {
    urls: {
      type: Array,
      default: () => [],
    },
    radius: {
      default() {
        return 0;
      },
    },
    type: {
      default() {
        return 'auto'; // square
      },
    },
  },
  model: {
    prop: 'urls',
    event: 'success',
  },
  methods: {
    uploadFile(e) {
      this.uploadFileByOss(e.file, (res) => {
        console.log(res);
        if (res.success) {
          let urls = this.urls;
          urls.push(res.data);
          this.$emit('success', urls);
          this.$emit('change', urls);
        }
      });
    },
    removeFile(index) {
      let urls = this.urls;
      urls.splice(index, 1);
      this.$emit('success', urls);
      this.$emit('change', urls);
    },
    /**
     * 上傳文件
     * @param { file } obj
     * @param { function } back
     */
    uploadFileByOss(file, back = () => {}) {
      getSignature().then((res) => {
        if (res.success) {
          let policyData = res.data;
          let ossUrl = policyData.host;
          let name = file.name || '';
          if (name) {
            name = '.' + file.name.split('.')[1];
          } else {
            name = '.png';
          }
          let accessUrl = policyData.dir + '/' + Date.now() + name; //上傳路徑
          let sendData = new FormData();
          sendData.append('OSSAccessKeyId', policyData['accessKeyId']);
          sendData.append('policy', policyData['encodedPolicy']);
          sendData.append('Signature', policyData['postSignature']);
          sendData.append('keys', policyData['dir']);
          sendData.append('key', accessUrl); //上传的文件路径
          sendData.append('success_action_status', 200); // 指定返回的状态码
          sendData.append('type', 'image/*');
          sendData.append('file', file);
          sendData.append('configType', 'ossFile');
          // 開始上傳
          ossUploadImg(ossUrl, sendData)
            .then((res) => {
              // 成功后没有回调， 只要觸發了 then , 就默認為成功
              let file = ossUrl + '/' + accessUrl; //获得到的url需要将其存数据库中
              back({
                success: true,
                data: file,
              });
            })
            .catch((e) => {
              back({
                success: false,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.com__upload-box {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  .remove-img {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 6;
  }

  & /deep/ .avatar-uploader {
    &:hover {
      filter: drop-shadow(0px 0px 0px black);
    }
    &.square {
      width: 60px;
      height: 60px;
      .avatar {
        width: 60px;
        height: 60px;
      }
    }
    .el-upload {
      width: 100%;
      aspect-ratio: 1;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      &::hover {
        border-color: #409eff;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100%;
      margin-top: 50%;
      transform: translateY(-50%);
      text-align: center;
    }
    .avatar {
      width: 100%;
      display: block;
    }
  }
  .px-img {
    width: 100%;
    margin-left: auto;
    position: relative;
    aspect-ratio: 1;
    img {
      width: 100%;
      vertical-align: middle;
    }
  }
}
</style>
